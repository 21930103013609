// --------------------
// VARIABLES
//---------------------

$headerheight   : 150px;  // Change to approximate header height - adds padding to first section
$header-starting-width: 197px;
$header-scrolled-width: 150px;

// Colors
$body-color		: #111111;
$primary        : #ffc21b;
$secondary      : #e1691d;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-800;

$color-gray: #f0f0f0;



// --------------------
// Container Options
//---------------------

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
);

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1140px,
	xml: 1450px,
	xxl: 1600px,
);