
ul, ol {
	&.styled-list {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			img, 
			svg {
				max-width: 44px;
			}
        }
	}
	&.check-list li,
	&.trainer-list li {
		position: relative;
		box-shadow: 0px 53px 117.6px 2.4px rgba(#404040, .07);
		padding: 3rem;
		margin-bottom: 8rem;
		@include media-breakpoint-up(sm) {
			margin-bottom: 2rem;
			padding: 3rem 3rem 3rem 0;
		}
		@include media-breakpoint-up(lg) {
			padding: 4rem 4rem 4rem 0rem;
		}
		@include media-breakpoint-up(xl) {
			padding: 6rem 6rem 6rem 0rem;
		}
		span {
			border-radius: 100%;
			box-shadow: 0px 53px 117.6px 2.4px rgba(#404040, .14);
			margin-left: auto;
			margin-right: auto;

		}
	}
	&.check-list {
		li {
			span {
				position: relative;
				display: block;
				z-index: 0;
				background-color: #fff;
				margin-top: -60px;
				min-width: 60px;
				max-width: 60px;
				height: 60px;
				@include media-breakpoint-up(sm) {
					margin-top: 0px;
					margin-left: -30px;
				}
				@include media-breakpoint-up(xl) {
					margin-left: -35px;
					height: 70px;
					min-width: 70px;
					max-width: 70px;
				}
				
				&:before {
					content: "";
					position: absolute;
					background: #e1691d;
					left: 20%;
					top: 20%;
					width: 40px;
					height: 40px;
					z-index: -1;
					border-radius: 100px;
				}
				img {
					width:60px;
					height:60px;
					top: -3px;
    				left: 10px;
					position: absolute;
					@include media-breakpoint-up(xl) {
						width:70px;
						height:70px;
						top: -6px;
						left: 12px;
					}
				}
			}
			
			&.list-1 {
				z-index: 5;	
			}
			&.list-2 {
				z-index: 4;	
			}
			&.list-3 {
				z-index: 3;	
			}
			&.list-4 {
				z-index: 2;	
			}
			&.list-5 {
				z-index: 1;
			}
		}
	}

	&.trainer-list {
		li {
			border: 7.5px solid #f0f0f0;
			@include media-breakpoint-up(sm) {
				padding-left: 10rem;
			}
			@include media-breakpoint-up(lg) {
				padding-left: 10rem;
			}
			@include media-breakpoint-up(xl) {
				padding-left: 16rem;
			}
			span {
				margin-top: -11rem;
				width:100px;
				height:100px;
				min-width: 100px;
				max-width: 100px;
				border: 8px solid white;
				@include media-breakpoint-up(sm) {
					position: absolute;
					margin-top: 0rem;
					left: -50px;
				}
				@include media-breakpoint-up(md) {
					left: -75px;
					height:150px;
					min-width: 150px;
					max-width: 150px;
					border: 15px solid white;
				}
				@include media-breakpoint-up(xl) {
					left: -120px;
					height:240px;
					min-width: 240px;
					max-width: 240px;
					border: 25px solid white;
				}
				img {
					max-width: 100%;
				}
			}
		}
	}

	&.contact-list {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			margin-bottom: 2rem;
			img, 
			svg {
				max-width: 28.5px;
			}
			strong {
				font-size: 1.9rem;
			}

			span {
				position: relative;
				background-color: #fff;
				border-radius: 100px;
				display: block;
				width: 50px;
    			height: 100%;
				min-width: 45px;
				&:before {
					content: "";
					position: absolute;
					background: #e1691d;
					left: 0;
					top: 0;
					width: 40px;
					height: 30px;
					z-index: -1;
					border-radius: 100px;
				}
				img {
					width: 50px;
					height: 50px;
					position: absolute;
					top: -18px;
					left: -8px;
					min-width: 50px;
				}
				
			}
			
        }
	}
}
  