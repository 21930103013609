form {
    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {
        width: 100% !important;
        height: unset !important;
        padding: 20px 15px 20px 50px !important;
        margin-bottom: 15px !important;
        border-radius: 5px !important;
        border: 1px solid rgba(#111111, .14) !important;
        background-color: #f0f0f0 !important;
        font-family: $body-font !important;
        font-size: 1.9rem !important;
        line-height: 1 !important;
        outline: none !important;
        @include media-breakpoint-up(lg) {
            padding: 34px 15px 34px 65px !important;
        }
        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
        }
    }

    textarea {
        height: 200px !important;
    }

    select {
        appearance: none;
    }

    label,
    .kajabi-form__form-item {
        position: relative;
        width: 100%;
        margin-bottom: 0 !important;
        line-height: 1;
        .icon {
            position: absolute;
            top: 20px;
            left: 15px;
            width: 15px;
            z-index: 999;

            @include media-breakpoint-up(lg) {
                left: 25px;
                top: 35px;
            }
            &.icon-arrow {
                left: 93%;
            }
            &.icon-glass {
                width: 10px;
                top: 15px;
                @include media-breakpoint-up(lg) {
                    top: 30px;
                }
            }
        }
    }

    label.error,
    .field-error {
        display: block;
        margin-bottom: 20px;
        margin-top: -10px;
        color: red;
        font-size: 1.5rem;
    }
    .form-messages {
        text-align: center;
        &.success {
            color: green;
        }
        &.error {
            color: red;
        }
    }
}

::placeholder {
    color: $body-color !important;
}

#kajabi-form {
    padding: 0;
    background-color: transparent;
    .kajabi-form {
        &__content {
            max-width: 100% !important;
        }

        &__title,
        &__subtitle {
            display: none;
        }
    }
}
