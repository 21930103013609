
// --------------------
// GLOBAL COMPONENTS
//---------------------

// Generic Section Padding - adds padding to each section - most of your sections should use this - override for special cases
section {
    padding: 60px 0px;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding: 80px 0px;
    }
    @include media-breakpoint-up(lg) {
        padding: 120px 0px;
    }
    &.less-pad {
        padding: 60px 0
    }
}

// Background Images - positions the background image behind the content in the section
.background-image-container {
    overflow: hidden;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

	img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
    }
    
    // Background Overlay - Adds a colour overlay with transparency options
    &.semi-transparent-bg {
        &-dark {
            background: #000;
            img {
                opacity: 0.5;
            }
        }
        &-light {
            background: #fff;
            img {
                opacity: 0.4;
            }
        }
    }
}

// Youtube Video Embeds
.video-preview-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    
    iframe,
    object,
    embed,
    img.poster,
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

	.play-button {
        width: 80px;
		position: absolute;
		left: calc(50% - 40px);
		top: calc(50% - 40px);
        @include media-breakpoint-up(lg) {
            width: 185px;
            left: calc(50% - 92.5px);
            top: calc(50% - 92.5px);
        }
		padding: 0;
		margin: 0;
		border: none;
		appearance: none;
		background: none;
		z-index: 5;
		transition: .8s ease;

		&:hover {
			transform: scale(1.1);
			cursor: pointer;
		}
		&:focus {
			outline: 0;
		}
	}
}

img {
    width: 100%;
}
.max-width-image {
    width: 100%;
    max-width: 300px;
    @include media-breakpoint-up(md) {
        max-width: 400px;
    }
    @include media-breakpoint-up(lg) {
        max-width: 100%;
    }
}
@include media-breakpoint-up(xml) {
    .min-width-image-xml {
        min-width: 770px;
    }
}

.container-fluid {
    max-width: 100%;
}

.color {
    &-primary {
        color: $primary;
    }
    &-secondary {
        color: $secondary;
	}
    &-body {
        color: $body-color !important;
    }
}

.bg {
    &-gray {
        background-color: $color-gray;
    }
}

.border-bottom-left-gray,
.border-top-center,
.border-bottom-center,
.border-bottom-left {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(50% - 45px);
    width: 90px;
    height: 1.5px;
    background-color: $secondary;
  }
}
.border-bottom-left:before {
    @include media-breakpoint-up(lg) {
        left: 0;
    }
}
.border-top-center:before {
    top: 0;
}
.border-bottom-left-gray:before {
    background-color: $color-gray;
    width: 200px;
    left: calc(50% - 100px);
    @include media-breakpoint-up(sm) {
        left: 0;
    }
}

.bg-white {
    box-shadow: 0px 20.5px 31px 9px rgba(#90111a, .05);
}