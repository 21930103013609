section {
    &#hero {
        padding-top: 120px;
        @include media-breakpoint-up(lg) {
            padding-top: 180px;
        }
        @include media-breakpoint-up(xxl) {
            padding: 200px 0;
        }
    }
    
    &#section-5 {
        .icon-header {
            max-width: 77px;
        }

        @media (min-width: 600px) {
            .swiper-container .swiper-button-next {
                right: 20%;
            }
            .swiper-container .swiper-button-prev {
                left: 20%;
            }
        }

    }

    &#section-6 {
        .bottom-right {
            position: absolute;
            width: 30%;
            height: 25%;
            right: 0;
            bottom: 0;
        }
    }
    
    &#section-7 {
        .bg-transparent-dark {
            background-color: rgba(#111111, .7);
            padding: 2rem;
            @include media-breakpoint-up(lg) {
                padding: 4rem;
            }
        }
    }

    &#section-8 {
        .left-center {
            position: absolute;
            width: 30%;
            height: 56%;
            left: 0;
            top: 20%;
        }
    }
    
    &#section-10 {
        background: linear-gradient(0deg, #ededed 5%, #ffffff 100%);

        .modules-col {
            overflow: auto;
        }
        .modules {
            min-width: 1200px;
        }
    }

    &#section-11 {
        .bottom-left {
            position: absolute;
            width: 80%;
            height: 65%;
            left: 0;
            bottom: 0;
            @include media-breakpoint-up(xxl) {
                width: 68%;
            }
        }
    }
    
    &#section-13 {
        img {
            max-width: 220px;
            @include media-breakpoint-up(md) {
                max-width: 415px;
            }
        }
    }
    
    &#section-15 {
        .swiper-slide {
            height: auto;
        }
        .section-2 {
            @include media-breakpoint-up(lg) {
                padding-top: 170px;
            }
        }

        .bg-transparent-dark {
            background-color: rgba(#111111, .7);
            p {
                max-width: 100%;
            }
            max-width: 73%;
            .location {
                max-width: 350px;
            }
            padding: 3rem;
            @include media-breakpoint-up(md) {
                padding: 6.5rem;
            }
        }
    }

    &#section-16 {
        padding-bottom: 45%;
        .background-image-container img {
            object-position: center bottom;
            object-fit: contain;
        }
    }
}