// --------------------
// Include Fonts Here
// --------------------


@font-face {
    font-family: 'Proxima Nova Regular';
    src: url('../../fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('../../fonts/ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


$headings-font: 'Work Sans', sans-serif;
$body-font: 'Proxima Nova Regular', sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 50%;  //1rem = 8px
    @include media-breakpoint-up(md) {
        font-size: 56.25%; //1rem = 9px
    }
    @include media-breakpoint-up(lg) {
        font-size: 62.5%; // 1rem = 10px
    }
}

html, body {
    @include font-smoothing('on');
    font-size: 1.9rem;
    line-height: 1.6;
    font-family: $body-font;
}

p {
    margin-bottom: 1.6rem;
}

// Headline padding
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    line-height: 1;
    margin-bottom: 2rem;
    font-family: $headings-font;
    font-weight: 600;
    text-transform: capitalize;
    color: $body-color;
}

h1, .h1 {
    font-size: 3.8rem;
    @include media-breakpoint-up(md) {
        font-size: 4.2rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 5.25rem;
    }
}

h2, .h2 {
    font-size: 3.5rem;
    @include media-breakpoint-up(lg) {
        font-size: 4.25rem;
    }
}

h3, .h3 {
    font-size: 2.5rem;
}
.h3 {
    font-weight: bold;
}

h4, .h4 {
    font-size: 2.25rem;
}

h5, .h5 {
    font-size: 2rem;
}

h6, .h6 {
    font-size: 1.8rem;
}

strong {
	font-family: $headings-font;
    font-size: 2.15rem;
}

.regular-font-style {
    font-weight: 400;
    color: $body-color;
}

// Hero Specific Font Sizes
.eyebrow {
    text-transform: uppercase;
    font-family: $headings-font;
    font-weight: 600;
    letter-spacing: 1.7px;
}