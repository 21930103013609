// --------------------
// BUTTON STYLE
//---------------------

.btn,
input[type=submit],
input[type=button],
button.kajabi-form__btn {
    width: 100%;
    border: 0 !important;
    border-radius: 5px !important;
    margin-top: 0 !important;
    margin-bottom: 12px !important;
    font-size: 2rem !important;
    line-height: 1 !important;
    text-transform: uppercase;
    padding: 20px 10px !important;
    white-space: normal;
    background: $primary !important;
    color: #fff !important;
    text-shadow: 0px 8px 16px rgba(42, 31, 0, 0.27) !important;
    font-family: $headings-font !important;
    font-weight: 700 !important;
    transition: .4s ease-in-out !important;
    @include media-breakpoint-up(lg) {
        padding: 38px 10px !important;
    }
    @include media-breakpoint-up(xl) {
        font-size: 2.25rem !important;
    }
    &:hover,
    &:focus,
    &:active {
        color: #000 !important;
    }
}

.btn-section {
    p {
        width: 90%;
        margin: 0 auto;
        font-size: 1.55rem;
    }
}