header {
    padding: 15px;
    background-color: transparent;
    transition: background-color 1s ease-in-out;
    &.scrolled {
        box-shadow: -3px -3px 6px 8px rgba(0,0,0,.05);
        background-color: $body-color;
    }
    .view-animation {
        opacity: 1;
    }
}

.header-logo img {
    transition: width 0.1s ease-in-out;
    width: $header-scrolled-width;
    @include media-breakpoint-up(lg) {
        width: $header-starting-width;
    }
    .scrolled & {
        width: $headerheight;
        @include media-breakpoint-up(lg) {
            width: $header-scrolled-width;
        }
    }
}

.header-phone {
    line-height: 1.2;
    text-decoration: none !important;
    color: $secondary;
    .phone-icon {
        img,
        svg {
            width: 23px;
        }
    }

    span {
        font-size: 1.9rem;
        font-family: $body-font;
    }
    em {
        font-style: normal;
        font-size: 2.7rem;
        font-family: $headings-font;
        font-weight: 600;
    }
}