#steps-block {
    .step {
        .icon-header {
            > img {
                left: 70%;
                top: 50%;
                z-index: -1;
            }
            span {
                img {
                    max-width: 65px;
                    &.large {
                        max-width: 80px;
                    }
                }
                max-width: 150px;
                height: 150px;
                border-radius: 100px;
                line-height: 150px;
            }
        }
        &-number {
            background: $primary;
            color: #fff;
            width: 70px;
            height: 70px;
            line-height: 70px;
            border-radius: 50%;
        }
    }
}